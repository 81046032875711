import myAxios from '../utils/request'

// 获取用户推品设置时间范围
const ApiGetUserPushTimeRange = (data = {}) => {
  return myAxios.get('/PushProduct/GetUserPushTimeRange')
}

// 设置用户推品时间范围
const ApiSetUserPushTimeRange = (data = {}) => {
  return myAxios.post('/PushProduct/SetUserPushTimeRange', {
    startTimeRange: data.startTimeRange,
    endTimeRange: data.endTimeRange,
  })
}
// 分页获取用户今日推送组列表
const ApiGetUserTodayPushGroupPage = (data = {}) => {
  return myAxios.post('/PushProduct/GetUserTodayPushGroupPage', {
    pageIndex: data.pageIndex,
    pageSize: data.pageSize,
    sortName: '',
    sortOrder: 0,
    // 官方分组ID
    officalId: data.officalId,
    // 托管群id
    roomId: data.roomId,
  })
}
// 分页获取商家今日推送组列表(重置获取)
const ApiGetBizTodayPushGroupPage = (data = {}) => {
  return myAxios.post('/PushProduct/GetBizTodayPushGroupPage', {
    pageIndex: data.pageIndex,
    pageSize: data.pageSize,
    sortName: '',
    sortOrder: 0,
    // 官方分组ID
    officalId: data.officalId
  })
}
// 用户今日推送组信息保存新
const ApiUserTodayPushGroupSaveNew = (data = {}) => {
  return myAxios.post('/PushProduct/UserTodayPushGroupSaveNew', {
    // 官方分组ID
    gIds: data.gIds,
    officalId: data.officalId,
    isPush: data.isPush,
    // 托管群id
    roomId: data.roomId
  })
}
export {
  // 获取用户推品设置时间范围
  ApiGetUserPushTimeRange,
  // 设置用户推品时间范围
  ApiSetUserPushTimeRange,
  // 分页获取用户今日推送组列表
  ApiGetUserTodayPushGroupPage,
  // 分页获取商家今日推送组列表(重置获取)
  ApiGetBizTodayPushGroupPage,
  // 用户今日推送组信息保存新
  ApiUserTodayPushGroupSaveNew
}
