<template>
	<div class="page">
		<div class="s2 p-card m-3">
			<div class="flex p-3 fs-16 pointer"
			     @click="goEdit">
				<div class="flex-1 b">今日推品计划（{{ list.length }}）</div>
				<div class="p-icon-arrow ml-1"/>

			</div>
			<div class="divider-dashed ml-3"/>
			<div class="b2">
				<no-data v-if="list.length===0"
				         title="暂无推品数据"
				         :show-button="false"/>
				<van-list v-model:loading="loading"
				          :finished="finished"
				          finished-text=""
				          @load="getDataList">
					<div v-for="(item,index) in list"
					     :key="index"
					     class="item pl-3">
						<div>
							<div class="img"><img :src="item.pushImg">
								<div class="tag"><span>{{ index + 1 }}</span></div>
							</div>
						</div>
						<div class="flex-1">
							<div class="p1"><b>{{ item.pushTitile }}</b></div>
							<div class="p2">{{ item.pushDes }}</div>
							<div class="p3"><span>{{ item.pushDate }} {{ item.pushDateTime }} 推送</span></div>
						</div>
						<div class="p4 pointer ml-2"
						     @click="previewClick(item)">
							<div class="icon"/>
							<div><u class="color-primary">预览</u></div>
						</div>
					</div>
				</van-list>
			</div>
		</div>

		<chat-list :id="id" v-model:show="dialog1"/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import chatList from '@/components/chatList/index.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import { defineAsyncComponent } from 'vue'
import { ApiGetUserTodayPushGroupPage } from '../../api/PushProduct'

export default {
	name: 'Index',
	components: {
		chatList
	},
	data() {
		return {
			dialog1: false,
			list: [],
			loading: false,
			finished: false,
			pageIndex: 1,
			pageSize: 15,
			id: 0
		}
	},
	computed: {
		...mapGetters(['globalData'])
	},
	created() {
	},
	methods: {
		async getDataList() {
			this.loading = true
			try {
				const res = await ApiGetUserTodayPushGroupPage({
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					sortName: '',
					sortOrder: 0,
					officalId: this.$route.query.officalId
				})
				this.pageIndex++
				const list = res ? res.dataList || [] : []
				this.list = [...this.list, ...list]

				if (!res || this.list.length >= res.recordCount) {
					this.finished = true
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				this.finished = true
				console.log(e)
			}
		},
		previewClick(item) {
			this.id = item.gId
			this.dialog1 = true
		},
		goEdit() {
			this.$store.commit('setGlobalData', {
				goods: this.list
			})
			this.$router.push({
				path: '/todayPushEdit',
				query: {
					officalId: this.$route.query.officalId
				}
			})
		}
	}
}
</script>

<style scoped lang="scss">
@import "index";


</style>
